// import Swiper JS
import Swiper, { Pagination, Navigation, Autoplay, EffectFade } from 'swiper';
import Player from '@vimeo/player';
import $ from 'jquery';

// TODO: optimised code for hero carousel and video
const heroCarouselVideo = ({ slides }) => {
  let players = [];

  slides.forEach(item => {
    const slide = item.querySelectorAll('[data-video-id]');
    if (slide.length < !0) {
      players.push(null);
      return;
    }
    const id = slide[0].dataset.videoId;

    const options = {
      id,
      background: true,
    }

    const player = new Player(slide[0], options);
    player.pause();
    players.push(player);
  });

  return players;
}

export const initHeroCarousel = () => {
  let videos = [];

  new Swiper('.js-hero-carousel', {
    autoplay: true,
    pagination: {
      el: '.js-hero-carousel-pagination',
      clickable: true,
    },
    modules: [Pagination, Autoplay],
    disableOnInteraction: false,
    on: {
      beforeInit: function () {
      },
      init: function () {
        videos = heroCarouselVideo(this);
      },
      afterInit: function () {
        if (videos[this.activeIndex] != null) {
          videos[this.activeIndex].play().then().catch(err => console.log(err));
        }
      },
      slideChange: function () {
        if (videos[this.previousIndex] != null) {
          videos[this.previousIndex].pause().then(() => {
            videos[this.previousIndex].setCurrentTime(0);
          }).catch(err => console.log(err));
        }
        if (videos[this.activeIndex] != null) {
          videos[this.activeIndex].play().then().catch(err => console.log(err));
        }
      },
    },
  })
}

const equalHeight = (selector, resize = true) => {
  var elements = document.getElementsByClassName(selector),
    allHeights = [],
    i = 0;
  if (resize === true) {
    for (i = 0; i < elements.length; i++) {
      elements[i].style.height = 'auto';
    }
  }
  for (i = 0; i < elements.length; i++) {
    var elementHeight = elements[i].clientHeight;
    allHeights.push(elementHeight);
  }
  for (i = 0; i < elements.length; i++) {
    elements[i].style.height = Math.max.apply(Math, allHeights) + 'px';
    if (resize === false) {
      elements[i].className = elements[i].className + ' show';
    }
  }
}

export const initInstagramCarousel = () => {
  new Swiper('.js-instagram-carousel', {
    slidesPerView: 1.55,
    spaceBetween: 0,
    navigation: {
      nextEl: '.js-instagram-carousel-button-next',
      prevEl: '.js-instagram-carousel-button-prev',
    },

    modules: [Navigation, Autoplay],
    breakpoints: {

      640: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2.25,
      },
      1024: {
        slidesPerView: 3,
      },
    },
    on: {
      afterInit: function () {
        equalHeight('js-insta-image-equal-height');
      },
      resize: function () {
        equalHeight('js-insta-image-equal-height');
      },
    },
  })
}

export const initClientsCarousel = () => {
  if ($(window).width() < 768) {
    new Swiper('.js-clients-carousel', {
      slidesPerView: 1.25,
      spaceBetween: 0,
      modules: [Pagination, Navigation],
      navigation: {
        nextEl: '.js-clients-carousel-button-next',
        prevEl: '.js-clients-carousel-button-prev',
      },
      pagination: {
        el: '.js-clients-carousel-pagination',
        clickable: true,
      },
    })
  }
}

export const initCarouselBlock = () => {
  const el = document.querySelectorAll('.js-carousel-block');

  el.forEach(item => {
    const slidesPerView = item.dataset.slidesPerView;
    new Swiper(item, {
      autoplay: {
        delay: 3000,
      },
      loop: true,
      slidesPerView,
      spaceBetween: 16,
      modules: [Navigation, Autoplay, Pagination],
      navigation: {
        nextEl: '.js-carousel-block-button-next',
        prevEl: '.js-carousel-block-button-prev',
      },
      pagination: {
        el: '.js-carousel-block-pagination',
        clickable: true,
      },
    })
  })
}

export const initTrendingArtcileCarousel = () => {
  new Swiper('.js-trending-articles', {
    slidesPerView: 1,
    spaceBetween: 16,
    modules: [Pagination],
    pagination: {
      el: '.js-trending-articles-pagination',
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2.25,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  });
}

export const initLatestWorksCarousel = () => {
  new Swiper('.js-latest-works-carousel', {
    slidesPerView: 1,
    spaceBetween: 16,
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: '.js-latest-works-carousel-next',
      prevEl: '.js-latest-works-carousel-previous',
    },
    pagination: {
      el: '.js-latest-works-carousel-pagination',
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 2,
      },
    },
  });

}
export const initInspirationCarousel = () => {
  new Swiper('.js-inspiration-carousel', {
    spaceBetween: 24,
    modules: [Pagination],
    pagination: {
      el: '.js-inspiration-carousel-pagination',
      clickable: true,

    },
    breakpoints: {

      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  });

}

export const initTestimonialCarousel = () => {
  testimonialCarousel();
  window.addEventListener('resize', function(){
    testimonialCarousel();
  })
}

const testimonialCarousel = () => {
  const options = {
    spaceBetween: 24,
    slidesPerView: 1,
    modules: [Pagination],
    pagination: {
      el: '.js-testimonial-carousel-pagination',
      clickable: true,
    },
  }

  if (window.innerWidth > 768) {
    options.modules = [EffectFade];
    options.effect = 'fade';
    options.allowTouchMove = false;
  }

  const testimonialSwiper = new Swiper('.js-testimonial-carousel', options);

  if (window.innerWidth > 768) {
    const paginations = document.querySelectorAll('.js-testimonial-author-pagination');

    paginations.forEach(item => {
      item.addEventListener('click', function () {
        document.querySelector('.js-testimonial-author-pagination.is-active').classList.remove('is-active');
        testimonialSwiper.slideTo(this.dataset.index);
        this.classList.add('is-active');
      });
    })
    return;
  }
}

export const initTestimonialCardsCarousel = () =>{
  new Swiper('.js-testimonials-cards-carousel', {
    spaceBetween: 24,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  })
}

export const initTalentsCarousel = () => {

  // el.style.width='416px'
  new Swiper('.js-talents-carousel', {
    slidesPerView: 'auto',
    loop: true,
    modules: [Navigation, Pagination],
    navigation: {
      prevEl: '.js-talents-carousel-prev',
      nextEl: '.js-talents-carousel-next',
    },
    pagination: {
      el: '.js-talents-carousel-pagination',
      clickable: true,
    },
  })
}

export const initOurServicesCarousel = () => {
  const swiper = new Swiper('.js-our-services-carousel', {
    slidesPerView: 1,
    spaceBetween: 0,
    modules: [EffectFade, Pagination],
    effect: 'fade',
    pagination: {
      el: '.js-our-services-carousel-pagination',
      clickable: true,
    },
    breakpoints: {
      768: {
        pagination: false,
      },
    },
  })

  const tabs = document.querySelectorAll('.js-our-services-tab')

  tabs.forEach(item=>{
    item.addEventListener('click', function(){
      swiper.slideTo(this.dataset.index);
      document.querySelector('.js-our-services-tab.is-active').classList.remove('is-active');
      this.classList.add('is-active');
    })
  })
}


export const initWorkWithCarousel = () => {
  new Swiper('.js-we-work-with-carousel', {
    slidesPerView: 1,
    spaceBetween: 0,
    modules: [Pagination],
    pagination: {
      el: '.js-we-work-with-carousel-pagination',
      clickable: true,
    },
  })
}
